import React, { ComponentType, LazyExoticComponent } from 'react';

import { Awaitable } from 'client/types/utils';

import { jsi18nReady } from 'flatfox_common/ui/utils/i18n';

/**
 * Wrapper around `React.lazy` that waits for additional readiness signals in addition
 * to the component having been loaded:
 *
 * - i18n
 * - iframe resizer if we're in an iframe
 */
export function createLazyComponent<
  // We need `any` here to be compatible with `React.lazy`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ComponentType<any>
>(factory: () => Awaitable<{ default: T }>): LazyExoticComponent<T> {
  return React.lazy(async () => {
    // Run the supplied factory function to kick off loading it.
    const componentPromise = factory();

    const prerequisites: Promise<unknown>[] = [
      // Wait for i18n to be ready
      jsi18nReady(),
    ];

    // If we're in an iframe, wait for it to be ready as well.
    if (window.iframeReadiness) {
      prerequisites.push(window.iframeReadiness);
    }

    await Promise.all(prerequisites);

    return componentPromise;
  });
}
