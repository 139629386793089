import filter from 'lodash/filter';
import sum from 'lodash/sum';

/**
 * Aggregates the `count` for a given list of unreads, for a given set of
 * filters.
 *
 * Input is expected to be a list of dicts, as coming from the unreads API
 * endpoint. `group_by` might have been applied in order to improve performance,
 * in which case it must contain the filters used here (e.g., if one wants to
 * filter for `role`, "role" must be in `group_by`).
 *
 * Theres an equivalent in python: sum_unreads_count().
 */
export function sumUnreadsCount(unreads, filters) {
  // eslint-disable-line import/prefer-default-export
  const filtered = filters ? filter(unreads, filters) : unreads;
  const counts = filtered.map((unread) => unread.count);
  return sum(counts);
}
