/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions } from '@tanstack/react-query';

import httpWrapper from '../../../../flatfox_common/httpWrapper';
import type { SocialLogin } from './v1.schemas';

/**
 * A REST endpoint for authentication via facebook.
 */
export const authFacebookCreate = (socialLogin: SocialLogin) =>
  httpWrapper<SocialLogin>({
    url: `/api/v1/auth/facebook/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: socialLogin,
  });

export const getAuthFacebookCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authFacebookCreate>>,
    TError,
    { data: SocialLogin },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authFacebookCreate>>,
  TError,
  { data: SocialLogin },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authFacebookCreate>>,
    { data: SocialLogin }
  > = (props) => {
    const { data } = props ?? {};

    return authFacebookCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthFacebookCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authFacebookCreate>>
>;
export type AuthFacebookCreateMutationBody = SocialLogin;
export type AuthFacebookCreateMutationError = unknown;

export const useAuthFacebookCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authFacebookCreate>>,
    TError,
    { data: SocialLogin },
    TContext
  >;
}) => {
  const mutationOptions = getAuthFacebookCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * A REST endpoint for authentication via Google Identity Services.
 */
export const authGoogleCreate = (socialLogin: SocialLogin) =>
  httpWrapper<SocialLogin>({
    url: `/api/v1/auth/google/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: socialLogin,
  });

export const getAuthGoogleCreateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authGoogleCreate>>,
    TError,
    { data: SocialLogin },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authGoogleCreate>>,
  TError,
  { data: SocialLogin },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authGoogleCreate>>,
    { data: SocialLogin }
  > = (props) => {
    const { data } = props ?? {};

    return authGoogleCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthGoogleCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authGoogleCreate>>
>;
export type AuthGoogleCreateMutationBody = SocialLogin;
export type AuthGoogleCreateMutationError = unknown;

export const useAuthGoogleCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authGoogleCreate>>,
    TError,
    { data: SocialLogin },
    TContext
  >;
}) => {
  const mutationOptions = getAuthGoogleCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
