import { Role } from '@api/v1';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import includes from 'lodash/includes';

import * as apiUrls from 'flatfox_common/api/apiUrls';
import * as config from 'flatfox_common/config';
import * as http from 'flatfox_common/http';
import { toggleClass } from 'flatfox_common/ui/utils/dom';
import { sumUnreadsCount } from 'flatfox_common/ui/utils/unreads';

import { addSignalListener } from 'flatfox_website/scripts/signals';

/**
 * Wires up the navbar's unreads updater. Re-entrant.
 */
export default function setupNavbar() {
  setupMobileNavbar();
  setupUnreadListener();
}

// Setup hamburger toggler on mobile.
function setupMobileNavbar() {
  const trigger = document.getElementById('navbar-mobile-toggler');
  if (!trigger) {
    return;
  }
  trigger.addEventListener('click', handleMobileNavbarClick);
}

function setupUnreadListener() {
  addSignalListener('unreadCountsChanged', updateUnreadCounts);
}

function handleMobileNavbarClick() {
  const navs = document.querySelectorAll('nav.fui-navbar');
  // Use for loop instead of forEach, because IE11 doesn't have forEach
  // on querySelectorResults.
  for (let i = 0; i < navs.length; i += 1) {
    toggleClass(navs[i], 'fui-navbar--is-open');
  }
}
/**
 * Gets the unread counts from the API and updates the DOM.
 */
function updateUnreadCounts() {
  const userPk = config.getUserPk();

  // We might be logged out, in which case we don't do anything.
  if (!userPk) {
    return;
  }

  const urlTemplate = apiUrls.messenger.unreadStates;
  const urlAction = 'groupby/role/internal/';
  http.get(apiUrls.list(urlTemplate + urlAction, { participant: userPk })).then(
    (unreads) => {
      const getCount = (filters) => sumUnreadsCount(unreads, filters);

      // Update in navbar
      updateCallout('applicant', getCount({ role: Role.applicant }));
      updateCallout('advertiser', getCount({ role: Role.advertiser, internal: false }));
      updateCallout(
        'advertiser-internal',
        getCount({ role: Role.advertiser, internal: true })
      );
      updateCallout('agent', getCount({ role: Role.agent, internal: false }));
      updateCallout('agent-internal', getCount({ role: Role.agent, internal: true }));
      updateCallout(
        'agent-notice',
        getCount({ role: Role['agent-notice'], internal: false })
      );
      updateCallout(
        'agent-notice-internal',
        getCount({ role: Role['agent-notice'], internal: true })
      );

      // Update title / for mobile
      const totalCount = getCount();
      updateTitle(totalCount);
      updateCallout('total', totalCount);
    },
    (error) => {
      const code = get(error, ['cause', 'error', 'code']);
      if (code && includes(code, 'not_authenticated')) {
        // We'll swallow PermissionDenied here, as it might just happen the user
        // has been logged out in the meantime.
        return;
      }

      if (code && includes(code, 'Failed to fetch')) {
        // After updating to the latest sentry sdk version, we ended up having
        // close to a 100k events, almost exclusively originating from android
        // webviews. Likely this is just some browser-specific behavior, that
        // does not affect us too much. Also note that the backend only gets
        // 1/3 of the errors reported by the frontend, hence 2/3 originate
        // from somewhere in the browser or network. (Cloudflare and Nginx
        // report the same number of 499s on the /unread api)
        return;
      }

      // This is something we might want to know about as it would hint to a
      // general problem.
      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        Sentry.captureMessage('FOX-39007: Error when reading unreads from API');
      });
    }
  );
}

/**
 * Update unread counts.
 * If current request is from a webview, use IFrame, otherwise update DOM.
 */
function updateCallout(name, count) {
  const nodes = document.querySelectorAll(`span[data-callout-name=${name}]`);
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    const num = count || 0;
    const numStr = num < 1000 ? num.toString() : `${Math.floor(num / 1000)}k`;
    node.setAttribute('data-callout-count', numStr);
    node.setAttribute('title', num);
  }
}

/**
 * Update unread counts in page title
 */
function updateTitle(count) {
  const { title } = document;

  // Remove previous unread counts from title.
  const match = title.match(/(\(\d+\)\s*)?(.*)/);
  const originalTitle = match ? match[2] : title;

  // ... and dd back.
  if (count > 0) {
    document.title = `(${count}) ${originalTitle}`;
  } else {
    document.title = originalTitle;
  }
}
