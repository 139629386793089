/* eslint-disable no-param-reassign */

export function hasClass(element, className) {
  return element.className.search(makeRegex(className)) > -1;
}

export function addClass(element, className) {
  if (!hasClass(element, className)) {
    element.className = `${element.className} ${className}`;
  }
  return element;
}

export function removeClass(element, className) {
  element.className = element.className.replace(makeRegex(className), '').trim();
}

export function toggleClass(element, className) {
  if (hasClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

function makeRegex(className) {
  return new RegExp(`\\b(${className})\\b`, 'g');
}
